.modal-backdrop.show {
    opacity: 0.8 !important;
}

.pointer-event{
    pointer-events:none !important;
}

.exportButton{
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.exportTransactionSection{
    display: inline-flex;
    margin-left: 100px;
}

.exportTransactionError{
    color: red;
    margin-left: 17vh;
    font-size: 0.8125rem;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

.exportToDateError{
    color: red;
    margin-left: 50vh;
    font-size: 0.8125rem;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

.filterButton{
    margin-left: 30px;
    margin-right: 20px !important;
}

.removeBottomPadding{
    padding-bottom: 0px !important;
}
.clearButton{
    margin-right: 30px !important;
    margin-left: 20px !important;
}
.exportDate .form-control{
    height: 25px !important;
}
.exportDateSpan{
    padding-left: 10px;
    padding-right: 10px;
}
.vAlignMiddle{
    display: inline-grid;
    align-items: center;
}
.agent-status .badge-pill {
    padding: 1em !important;
    display: block;
}
