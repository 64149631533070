.icon-60 {
  width: 60px;
  height: 60px;
}

.icon-40 {
  width: 40px;
  height: 40px;
}

.icon-35 {
  width: 35px;
  height: 35px;
}

.icon-17 {
  width: 17px;
  height: 17px;
}

.span-10 {
  opacity: 0.5;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.37px;
}

.span-12 {
  opacity: 0.5;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.37px;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-light {
  background-color: #f6f9fc !important;
}

.hoverBg:hover {
  background-color: #cfcfcf28;
}

.shimmer{

  .shine {
    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px; 
    display: inline-block;
    position: relative; 
    
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards; 
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
    }

  box {
    height: 104px;
    width: 100px;
  }

  div {
    display: inline-flex;
    flex-direction: column; 
    // margin-left: 25px;
    margin-top: 15px;
    vertical-align: top; 
  }

  .line {
    height: 10px;
    margin-top: 10px;
    width: 200px; 
  }

  photo {
    display: block!important;
    width: 325px; 
    height: 100px; 
    margin-top: 15px;
  }

  @-webkit-keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }
    
    100% {
      background-position: 468px 0; 
    }
  }
}

.time-picker {
    background: lightgray;
    position: absolute;
    // right: 24px;
    right: 0px;
    // left: 24px;
    width: max-content;
    min-width: 100px;
    z-index: 10;
    max-height: 253.3px;
    border-radius: 6px;
    box-shadow: 0 2px 23px 0 #ededed;
    background-color: #ffffff;
    overflow-y: scroll;

    div {
        // opacity: 0.3;
        // background-color: #d8d8d8;
        opacity: 0.8;
        font-family: Open Sans, sans-serif;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.24px;
        color: #22334f;
        cursor: pointer;

        &:hover {
            background-color: #d8d8d8;
        }
    }
    .active {
        background-color: #d8d8d8;
    }
  }

.border-dashed {
  border: 1px dashed lightgray !important;
}

.filter-bg {
  background-color: #f6f9fc;
}

.reactDateTimeCustomClass {
  input {
    width: 0 !important;
    height: 0;
    padding: 0;
    visibility: hidden;
  }
}

.min-width-200px {
  min-width: 200px;
}

.min-width-400px {
  min-width: 400px;
}

.rdtPicker-right-0 {
  .rdtPicker {
    right: 0;
  }
}

.report-item {
        border-bottom: 1px solid #8080802b;
    &:hover {
        background: #8080802b;
    }
}
::placeholder {
    color:#adb5bd;
  }

  .loader-container{
    position: fixed;
    height: 100vh;
    right: 0;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 200;
  }

.rounded-5{
  border-radius: 5px;
}
.rounded-10{
  border-radius: 10px;
}
.rounded-15{
  border-radius: 15px;
}
.rounded-20{
  border-radius: 20px;
}
.rounded-25{
  border-radius: 25px;
}
.rounded-30{
  border-radius: 30px;
}
.w-400 {
  min-width: 400px;
  width: 400px;
}
.w-250 {
  min-width: 250px;
  width: 250px;
}
.w-200 {
  min-width: 200px;
  width: 200px;
}
.max-w-100 {
  max-width: 100px;
}
.min-w-100 {
  min-width: 100px;
}
.w-60 {
  max-width: 60px;
}
.w-40 {
  max-width: 40px;
}
.w-30 {
  width: 30px;
}
.w-20 {
  max-width: 20px;
}
.w-10 {
  max-width: 10px;
}
.mh-400 {
  min-height: 400px;
}
.mh-250 {
  min-height: 250px;
}
.mh-200 {
  min-height: 200px;
}
.mh-100 {
  min-height: 100px;
}
.mh-150 {
  min-height: 150px;
}
.h-80 {
  height: 80px;
}
.h-60 {
  height: 60px;
}
.mh-40 {
  max-height: 40px;
}
.h-40 {
  height: 40px;
}
.mh-30 {
  max-height: 30px;
}
.mh-20 {
  max-height: 20px;
}
.fnt-10{
  font-size: 10px !important;
}
.fnt-12{
  font-size: 12px !important;
}
.fnt-14{
  font-size: 14px !important;
}
.fnt-16{
  font-size: 16px !important;
}
.fnt-18{
  font-size: 18px !important;
}
.fnt-20{
  font-size: 20px !important;
}
.fnt-22{
  font-size: 22px !important;
}
.fnt-24{
  font-size: 24px !important;
}
.fnt-26{
  font-size: 26px !important;
}
.fnt-28{
  font-size: 28px !important;
}
.fnt-30{
  font-size: 30px !important;
}
.bg-transparent-50 {
  background-color: rgba(34, 51, 79, 0.17);
}
.shadow-bg{
  box-shadow: 0 0 32px 0 rgba(136,152,170,0.32);
}

.pnbMet-life-color-light{
  background-color: #f3f7f7;
}
.pnbMet-life-color-dark{
  background-color: #dfebe9;
}

.india-first-color-light{
  background-color: #eff1fb;
}
.india-first-color-dark{
  background-color: #d7dcf6;
}

.icici-color-light{
  background-color: #f9f9eb;
}
.icici-color-dark{
  background-color: #efefcc;
}

.india-first-sa-color-light{
  background-color: #f7edf5;
}
.india-first-sa-color-dark{
  background-color: #ebd3e5;
}

.india-first-rp-color-light{
  background-color: #f2f8ff;
}
.india-first-rp-color-dark{
  background-color: #dfeeff;
}

.india-first-aci-color-light{
  background-color: #eff6eb;
}
.india-first-aci-color-dark{
  background-color: #d6e8cc;
}

.border-transparent{
  border: 1px solid transparent !important;
}
.required:after {
  content:" *";
  color: red;
}