.cursor-pointer {
    cursor: pointer;
}

.tag-behaviour {
    transition: all 1.15s ease;
}

.tag-behaviour span {
    display: none;
}

.tag-behaviour:hover span {
    display: inherit;
}

.background-primary {
    background-color: #2dce89;
}

.bg-lightgray-50 {
    background-color: #22334f4d;
}

.z-index-0 {
    z-index: 0;
}

.z-index-10 {
    z-index: 10;
}

.z-index-50 {
    z-index: 50;
}

.z-index-60 {
    z-index: 60;
}

.z-index-100 {
    z-index: 100;
}

.opacity-50{
    opacity: .5;
}