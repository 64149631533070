.dot{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 3px;
}

.shine {
    background: #f6f7f8d8;
    /* background-image: linear-gradient(to right, #acbbca 0%, #edeef1 20%, #f6f7f8 40%, #d4dce4 100%); */
    background-image: linear-gradient(to right, #dfe5ea 0%, #edeef1 20%, #f6f7f8 40%, #d4dce4 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    display: inline-block;
    position: relative;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
}

@-webkit-keyframes placeholderShimmer {
    0% {
        background-position: -368px 0;
    }
    100% {
        background-position: 368px 0;
    }
}