
.custom-border-card li {
  background-color: rgba(93, 93, 93, 0.034);
  margin-bottom: 4px;
}
.custom-border-card1 li {
  background-color: rgba(93, 93, 93, 0.034);
  margin-bottom: 4px;
}
.text-random {
  color: #22334f;
}
.bg-color-a {
  // rgba(229, 255, 242, 0.6)
  background-color: #fceee3b0 !important;
  box-shadow: none;
}
.bg-color-b {
  // rgb(255, 241, 241)
  background-color: #def2fff5 !important;
  box-shadow: none;
}
.bg-color-c {
  // #dff0f9
  background-color:  #e0f4f9 !important;
  box-shadow: none;
}

.current-week-shadow {
  box-shadow: 4px 4px 20px rgba(51, 51, 51, 0.15);
  z-index: 1;
}
.custom-scroll{
  overflow-x: scroll;
}
.custom-scroll::-webkit-scrollbar{
  display: none;
}
