.custom-width{
  width: 140%;
}
.empty-card {
  width: 100%;
  height: 178px;
}
.icon-padding{
  padding: 11px 0;
}
.margin-top{
  margin-top: 250px;
}
.margin-top1{
  margin-top: 200px !important;
}

.dashed-border{
  border-style: dashed !important;
}


  
  .table-slide-pane{
    background-color: #666;
    width: 100%;
    z-index: 5;
    overflow: hidden;
    transition: all 5s linear;
  }

  .up{
    display: none;
    height:0px;
    transition: height 5s linear;
  }

  .down{
    display: block;
    height: 100px;
    transition: height 5s linear;
  }
