.reactDateTimeCustomClass {
    input {
        width: 0!important;
        height: 0;
        padding: 0;
        visibility: hidden;
    }
}

.search-field {
    height: 34px;
    @media only screen and (max-width: 768px) {
        max-width: 122px;
        display: inline;
        float: left;
        height: 30px;
    }
}

.import-export-btn-group {
    @media only screen and (max-width: 768px) {
        float: right;
    }
}

.filter-form {
    @media only screen and (max-width: 768px) {
        // float: right;
        margin-bottom: 10px;
    }
}
.upper-header-col{
    @media only screen and (max-width: 768px) {
       text-align: right;
    }
  }
// Dealer Details Page Custom Tabs Start
.searchWidth {
  width: 200px;
}
.custom-tabs-last {
  border-right: none;
}
.custom-hover:hover {
  cursor: pointer;
  h6 {
    color: #5e72e4;
  }
}
// Tabs animation
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.bttn {
  cursor: pointer;
}
span::before,
span::after {
  content: "";
  width: 1px;
  height: 0;
  position: absolute;
  transition: all 0.17s linear;
  background: #5e72e4;
}
.bttn:hover::before,
.bttn:hover::after {
  width: 100%;
}
.bttn:hover span::before,
.bttn:hover span::after {
  height: 100%;
}
/*----- Div -----*/
.bttn::before,
.bttn::after {
  transition-delay: 0.2s;
}
.bttn span::before,
.bttn span::after {
  transition-delay: 0s;
}
.bttn::before {
  right: 0;
  top: 0;
}
.bttn::after {
  left: 0;
  bottom: 0;
}
.bttn span::before {
  left: 0;
  top: 0;
}
.bttn span::after {
  right: 0;
  bottom: 0;
}
.custom-active {
  border-right: 1px solid #5e72e4;
  border-left: 1px solid #5e72e4;
  background-color: #fff !important;

  h6 {
    color: #5e72e4;
  }
}
.custom-active-color {
  background-color: #fff;
}

// Dealer Details Page Custom Tabs End

@media (max-width: 860px) {
  .flx-container {
    flex-direction: column;
  }
  .flx-group {
    flex-direction: row;
  }
}