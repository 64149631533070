.avatar-edit {
  width: 100px;
  height: 100px;
}
.camera-mask {
  //   background: rgba(0, 0, 0, 0.418);
  //   opacity: 0.3;
  height: 100px;
  width: 100px;
  margin-top: -100px;
  border-radius: 50%;

  position: relative;
  overflow: hidden;
  .bottom-div {
    // background: rgba(255, 255, 255, 0.719);
    height: 30px;
    bottom: 0;
    position: absolute;
    width: 100%;
    cursor: pointer;

    i {
      color: #fff;
      opacity: .5;
    }
  }
}
.camera-mask:hover {
  background: rgba(0, 0, 0, 0.418);
  .bottom-div {
    background: rgba(59, 59, 59, 0.719);

    i {
        opacity: 1;
      }
  }
}
