.checkbox {
  width: 100%;
  margin: 15px auto;
  position: relative;
  display: block;

  label {
    position: relative;
    min-height: 34px;
    display: block;
    padding-left: 40px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;

    span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      margin: 4px;
      width: 22px;
      height: 22px;
      transition: transform 0.28s ease;
      border-radius: 3px;
      border: 2px solid $light;
    }

    &:after {
      content: '';
      display: block;
      width: 10px;
      height: 6px;
      border-bottom: 2px solid $warning;
      border-left: 2px solid $warning;
      transform: rotate(-45deg) scale(0);
      transition: transform ease 0.25s;
      position: absolute;
      top: 12px;
      left: 10px;
    }
  }

  input[type="checkbox"] {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
    margin-left: -20px;

    &:checked ~ label {
      &:before {
        border: 2px solid $warning;
      }

      &:after {
        transform: rotate(-45deg) scale(1);
      }
    }

    &:focus + label::before {
      outline: 0;
    }
  }
}

.msg-template-box {
  border-radius: 8px;
  //border: 1px solid #cccccc;
}

.msg-template-body {
  min-height: 250px;
}

.transactions-annual-box {
  padding: 20px;
  width: 300px;
  height: 120px;
  background-color: #f6f9fc;
  border-radius: 6px;
}

.transactions-monthly-box {
  padding: 20px;
  width: 100%;
  height: 120px;
  background-color: #f6f9fc;
  border-radius: 6px;
}

.ui.dropdown .menu .selected.item, .ui.dropdown.selected {
  background-color: transparent !important;
}

.ui.dropdown .menu > .item:hover {
  background-color: #f6f9fc !important;
}

.ui.dropdown .menu {
  box-shadow: 0 0 32px 0 rgba(136, 152, 170, 0.15) !important;
  border: 0 !important;
  z-index: 100 !important;
}

.ui.dropdown .menu .active.item {
  font-weight: 400 !important;
  color: #525f7f !important;
}

.fa-outline {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #5e72e4;
}

.inputStyle {
  width: 40px !important;
  height: 40px;
  margin: 5px;
  font-size: 18px;
  border-radius: 5px;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.11);
  box-shadow: inset 1px 2px 4px 1px rgba(0, 0, 0, 0.11);
  background-color: #ecf0f4;
}
.customBtn {
  display: flex;
  justify-content: center;
}
.customBtn1 {
  display: flex;
  justify-content: center;
}

.otpMessage {
  display: flex;
  justify-content: start;
}
.seconds {
  padding: 14px 0;
}
/* Start Other Policy Document Section CSS */
.policyDocPrevNextBtn {
  font-size: 20px; 
  border: 0px; 
  background: none;
  cursor: pointer;
}
.policyDocPrevNextBtn:disabled {
  cursor: default
}
.fsize16 {
  font-size: 16px;
}
.other-policy-documents .carousel-indicators {
  bottom: -50px;
}
.other-policy-documents .carousel-indicators li {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin: 10px;
  text-indent: 0;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background-color: #cccccc;
  box-shadow: inset 1px 1px 1px 1px rgba(0,0,0,0.5);    
}
.other-policy-documents .carousel-indicators .active {
  width: 18px;
  height: 18px;
  margin: 10px;
  background-color: #000000;
}
.other-policy-documents .carousel-control-prev, .carousel-control-next {
  opacity: 1;
}
.other-policy-documents .carousel-control-prev {
  left: -55px;
}
.other-policy-documents .carousel-control-next {
  right: -55px;
}
.other-policy-documents .carousel-control-prev-icon,.carousel-control-next-icon {
  filter: invert(1)
}
.pdf-container {
  min-height: 500px;
}
.pdf-container-zoom {
  overflow: hidden;
  overflow-x: scroll
}
/* End Other Policy Document Section Css */